<template lang="pug">
include ../pug/svg
section#pallet
	div.wrap
		div.pallet
			h4 Colour Pallet
			button.but.circ.plus.text(type="button" @click="toggleModal(true)")
				span Add Colour
				+svg(svg-filename="iconPlus" aria-hidden="true" alt="Add icon")
			ul
				li.field(v-for="(hex, index) in cssPallet" :key="index" :hex="hex" :index="index")
					input.hex(type="text" minlength="6" maxlength="6" placeholder="None" :value="hex.hex" @input="updatePalletColour(index, $event)")
					button.colour(type="button" disabled :style="{'background-color':hexColour(hex.hex)}")
					label Colour Name / Hex
					//-span.on(contenteditable="true" @input="updatePalletName(index, $event)") {{hex.name}}
					input.name(type="text" placeholder="Unsupplied" :value="hex.name" @input="updatePalletName(index, $event)")
					button.bin.but(v-if="allowBin" type="button" @click="binColour(index)")
						+svg(svg-filename="iconBin" aria-hidden="true" alt="Bin icon")
		div.toggles
			//-Search(v-model:search="search")
			template(v-for="(group, groupKey) in cssVarMap")
				h4 {{group.name}}
				div.field.c2(v-for="(cssVar, cssVarKey) in group.vars")
					select(@change="updatePalletMap(groupKey, cssVarKey, $event.target.value)")
						option(v-for="(hex, index) in cssPallet" :value="index" :selected="index===cssVar.hex") {{hex.name}}
					button.colour(type="button" disabled :style="{'background-color':hexColour(cssPallet[cssVar.hex].hex)}")
					label {{cssVar.label}}
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
					
	div.modal.narrow.center(:class="{active:showModal}")
		div.bar
			+svg(svg-filename="iconMore" aria-hidden="true")
			span Add Colour
			button.but(type="button" @click="toggleModal(false)") Close
				+svg(svg-filename="iconClose")
		Form.body(ref="colourForm" :validation-schema="colourSchema" v-slot="{ errors, isSubmitting, values, meta, setFieldError }" @submit="addColour")
			//-fieldset.fgroup
			div.field
				Field(name="name" type="text" placeholder="Unsupplied" autocomplete="off" :validateOnInput="false" required)
				label Colour Name
				transition(name="fade")
					ErrorMessage.err(name="name" as="span")
			div.field
				Field(name="hex" type="text" placeholder="Unsupplied" autocomplete="off" :validateOnInput="false" maxlength="7" required)
				label Colour Hex
				transition(name="fade")
					ErrorMessage.err(name="hex" as="span")
			div.foot
				span.req Required
				button.but.pri.spinner(type="submit" :class="{spin:isSubmitting}" :disabled="!meta.valid||isSubmitting||!meta.touched||disableInvite") Add
				button.but.sec(type="button" @click="toggleModal(false)") Cancel
				
	div.modal.narrow.center(:class="{active:deleteColour}")
		div.bar
			+svg(svg-filename="iconMore" aria-hidden="true")
			span Delete Colour
			button.but(type="button" @click="cancelDelete") Close
				+svg(svg-filename="iconClose")
		div.body
			p Please select a replacement colour.
			div.field.req
				select(v-model="replacementColour")
					option(:value="null") Unsupplied
					template(v-for="(hex, index) in cssPallet")
						option(v-if="index!==deleteColour" :value="index") {{hex.name}}
				label Colour Name
				+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
			div.foot
				span.req Required
				button.but.pri(@click="confirmDelete" :disabled="!replacementColour") Continue
				button.but.sec(@click="cancelDelete") Cancel
</template>

<script>
import contenteditable from 'vue-contenteditable';
//import Search from '../components/Search';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';

export default {
	name: 'ManageThemeStyle',
	props: ['cancelled'],
	emits: ['storeUpdated'],
	data() {
		const hexRegex = /^[0-9A-F]{6}$/i;

		const colourSchema = Yup.object().shape({
			hex: Yup.string().label('Colour Hex').required()
			.test({
				name: 'hex',
				exclusive: true,
				message: 'Invalid hex code',
				params: { hexRegex },
				test: (value) => {
					return hexRegex.test(value);
				},
			}),
			name: Yup.string().label('Colour Name').required(),
		});
		
		return {
			hexRegex,
			colourSchema,
			showModal: false,
			deleteColour: null,
			replacementColour: null,
			previewIndex: 0,
			search: null,
		};
	},
	components: {
		Form,
		Field,
		ErrorMessage,
		contenteditable,
		//Search,
	},
	mounted() {
		this.$store.dispatch('gui/setHeader', {
			title: this.terminology('interface', 'manage', 'singular') + ' ' + this.terminology('theme','theme','singular'),
			backRoute: {
				text: 'Dashboard',
				name: 'Dashboard',
			},			
		});
	},
	computed: {
		cssPallet() {
			// sort by name
			const cssPallet = this.$store.state.gui.cssPallet;
			/*
			let keys = Object.keys(cssPallet).sort((a,b) => cssPallet[a].name.localeCompare(cssPallet[b].name));

			let sorted = {};

			for(let k of keys) {
				sorted[k] = cssPallet[k];
			}
			
			return sorted;
			*/
			return cssPallet;
		},
		allowBin() {
			return Object.keys(this.cssPallet).length > 1;
		},
		cssVars() {
			return this.$store.getters['gui/cssVars'];
		},
		cssVarMap() {
			return this.$store.state.gui.cssPalletMap;
		},
	},
	methods: {
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		toggleModal(bool) {
			this.showModal = bool;

			if (this.showModal) {
				this.$refs.colourForm.resetForm();
				this.$store.dispatch('gui/setTintState', true);
			} else {
				this.$store.dispatch('gui/setTintState', false);
			}
		},
		confirmDelete() {
			// update usage with replacement colour
			for (let groupKey in this.cssVarMap) {
				for (let cssVarKey in this.cssVarMap[groupKey].vars) {
					if (this.cssVarMap[groupKey].vars[cssVarKey].hex === this.deleteColour) {
						this.updatePalletMap(groupKey, cssVarKey, this.replacementColour);
					}
				}
			}
			
			// delete colour
			this.$store.dispatch('gui/binColour', {
				hexKey: this.deleteColour,
			});
			
			this.cancelDelete(); // close modal
			
			this.$emit('storeUpdated', {
				screen: 'pallet',
				valid: true,
			});
		},
		cancelDelete() {
			this.deleteColour = null;
			this.replacementColour = null;
			this.$store.dispatch('gui/setTintState', false);
		},
		binColour(hex) {
			this.deleteColour = hex;
			this.$store.dispatch('gui/setTintState', true);
		},
		addColour(values) {
			this.$store.dispatch('gui/addColour', values);
			
			this.$emit('storeUpdated', {
				screen: 'pallet',
				valid: true,
			});
			
			this.toggleModal(false);
		},
		updatePalletMap(groupKey, cssVarKey, hex) {
			this.$store.dispatch('gui/setCssPalletMap', {
				groupKey,
				cssVarKey,
				hex,
			});
			
			this.$emit('storeUpdated', {
				screen: 'pallet',
				valid: true,
			});
		},
		hexColour(value) {
			// check for valid hex values (e.g(#FFF / #FFFFFF)
			const valid = this.hexRegex.test(value);
			
			return valid ? '#'+value : 'transparent';
		},
		updatePalletColour(hexKey, event) {
			let valid = false;
			
			if (this.hexRegex.test(event.target.value)) {
				// valid hex
				this.$store.dispatch('gui/setCssPalletHex', {
					hexKey,
					hex: event.target.value.toUpperCase(),
				});
				
				valid = true;
			}
			
			this.$emit('storeUpdated', {
				screen: 'pallet',
				valid,
			});
		},
		updatePalletName(hexKey, event) {
			// clense text
//			const div = document.createElement('div');
//			div.innerHTML = event.target.innerText;
//			const name = div.innerText.replace(/\r?\n|\r/g, ' ').replace(/\s+/g, ' ').trim();
			
			this.$store.dispatch('gui/setCssPalletName', {
				hexKey,
//				name: event.target.innerText,
				name: event.target.value,
			});
			
			this.$emit('storeUpdated', {
				screen: 'pallet',
				valid: true,
			});
		},
	},
}
</script>

<style lang="scss">
#pallet {
	padding-top: 20px;
	.wrap {
		display: flex;
		align-items: flex-start;
		>div {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
		h4 {
			display: flex;
			align-items: center;
			min-height: 30px;
			font-size: 1.6rem;
		}
		.field {
			margin-bottom: 10px;
		}
	}
	.toggles {
		flex-grow: 1;
		.search,
		h4 {
			margin: 10px 0 20px 0;
			&:first-of-type {
				margin-top: 0;
			}
		}
		h4 {
			width: 100%;
		}
	}
	.pallet {
		position: sticky;
		top: 100px;
		width: 300px;
		min-width: 280px;
		margin-right: 40px;
		.field {
			padding-right: 110px;
		}
		label {
			padding-right: 0;
		}
		[contenteditable] {
			cursor: auto;
			width: calc(100% - 110px);
			&:empty:before {
				content: 'Enter Name...';
			}
		}
		ul {
			width: 100%;
		}
		.hex,
		.colour {
			transition: 0.3s ease-in-out;
		}
		.bin {
			position: absolute;
			top: 50%;
			right: 100%;
			transform: translateY(-50%);
			height: 30px;
			width: 30px;
			opacity: 0;
			pointer-events: none;
			&:before {
				display: none;
			}
			svg {
				margin: auto;
			}
			.fill {
				fill: var(--lab-col) !important;
			}
			&:hover {			
				.fill {
					fill: var(--pri-bg) !important;
				}
			}
		}
		li {
			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: 100%;
				width: 30px;
				height: 100%;
			}
			&:hover {
				.bin {
					pointer-events: auto;
					opacity: 1;
				}
			}
		}
	}
	@media only screen and (max-width:840px) {
		.toggles {
			.field {
				max-width: none;
				width: 100%;
			}			
		}
	}
}
</style>
